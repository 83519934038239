<template>
  <section v-if="slice && storyData" id="features-story" class="homepage-full-width-story" :style="getBanner">
    <div class="overlay" />
    <b-container>
      <div class="homepage-full-width-story__content">
        <div class="story-link">
          <b-link
            tabindex="0"
            v-if="storyData.data.category"
            :style="`background-color: ${getCategoryColour(storyData.data.category)}`"
            class="taglink"
            :class="'taglink--' + storyData.data.category.toLowerCase()"
            :to="'/our-values/' + storyData.data.category.toLowerCase()">
            <span :style="`color: ${getCategoryTextColour(storyData.data.category)}`">
            {{ storyData.data.category }}
            </span>
          </b-link>
          <b-link class="story-link__content" tabindex="0" :to="`/story/${storyData.uid}`" :style="`background-color: ${getCategoryColour(storyData.data.category)}`">
            <h1><span :style="isMobile() ? `color: ${getCategoryTextColour(storyData.data.category)}` : ''">{{ storyData.data.title[0].text }}</span></h1>
            <p v-if="storyData.data.subline">
              <span :style="isMobile() ? `color: ${getCategoryTextColour(storyData.data.category)}` : ''">{{ storyData.data.subline[0].text }}</span>
            </p>
          </b-link>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "homepage-fullWidthStory",
  data() {
    return {
      storyData: null
    }
  },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  async mounted() {
    this.storyData = await this.$prismic.client.getByUID('story', this.slice.primary.story_link.uid
    );
  },
  computed: {
    getBanner() {
      try {
        const backgroundImageUrl = this.storyData.data.main_image.url
        return {
          backgroundImage: 'url("' + backgroundImageUrl + '")'
        }
      } catch (error) {
        return console.error('Error', error)
      }
    },
  }
}
</script>

<style lang="scss">
.homepage-full-width-story {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 15%, rgba(0, 0, 0, 0.9) 100%);
    z-index: -0;

    @include respond-below(ipad) {
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
    }
  }

  &__content {
    display: block;
    height: 100vh;
    max-height: 830px;
    max-width: 46%;
    color: #fff;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    overflow: hidden;

    @include respond-below(sm) {
      max-width: 100%;
      display: block;
    }

    .story-link {
      &__content {
        span {
          transition: .2s ease-in-out;
        }

        h1 {
          color: #fff;
          margin-bottom: 15px;
          transition: .2s ease-in-out;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        }

        p {
          color: #fff;
          margin-bottom: 20px;
          transition: .2s ease-in-out;
          padding-right: 40%;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        }

        @include respond-above(sm) {
          &:hover,
          &:focus {

            h1,
            p {
              span {
                text-shadow: none;
                background-color: black;
              }
            }
          }
        }

        @include respond-below(sm) {
          h1,p {
            text-shadow: none;
          }
        }
      }
    }

    @include respond-below(ipad-pro) {
      max-width: 60%;
      height: 60vh;
    }

    @include respond-below(ipad) {
      max-width: 100%;
      margin: 50px 0;
    }

    @include respond-below(sm) {
      height: auto;
      margin: 0;

      .story-link {
        .taglink{
          margin: 12px;
          margin-bottom: 35px;
        }

        &__content {
          padding: 35px 12px;
          display: block;

          h1 {
            font-size: 35px;
            line-height: 42px;
            margin-bottom: 25px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    padding-top: 350px;
    background-position: top center;

    .container{
      padding: 0;
    }
  }
}
</style>
