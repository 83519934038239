/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */
export default function linkResolver(doc, prismicClient = null) {

    if (doc.isBroken) {
        return '/not-found'
    }

    if (doc.type === 'home'
    ) {
        return '/'
    }
    if (doc.type === 'issue') {
        return '/issue/' + doc.uid
    }
    if (doc.type === 'past_issue' && doc.data.issue_pdf_file.url) {
        return doc.data.issue_pdf_file.url
    }
    if (doc.type === 'page'
    ) {
        let link = '/' + doc.uid
        // if link starts with // then remove one /
        link = link.replace(/\/\//g, '/')
        return link
    }

    if (doc.type === 'story') {
        return '/story/' + doc.uid;
    }
    // if type is news_item, console log the doc
    if (doc.type === 'news_item') {
        //  log parent_news_page
        if (doc.data.parent_news_document) {
            let parentSlug = linkResolver(doc.data.parent_news_document, prismicClient)
            // now add the parent slug to the news item slug
            let newsSlug = doc.data.regional_tag ? globalSlugifyLink(doc.data.regional_tag) : doc.id
            let slug = parentSlug + '#' + newsSlug
            return slug
        } else {
            return '/not-found'
        }
    }

    return '/' + doc.type + 'slug-not-found'
}

function globalSlugifyLink(string) {
    try {
        return string
            .toString()
            .toLowerCase()
            .replace(/&/g, "and")
            .replace(/\s+/g, "-");
    } catch (e) {
        return "";
    }
}
