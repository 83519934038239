<template>
  <section v-if="slice && slice.primary" id="homepage-welcome" class="homepage-welcome">
    <b-container class="homepage-welcome__container" id="content-main">
      <b-row>
        <b-col sm="12" md="8" lg="8" offset-lg="2" offset-md="2">
          <div class="homepage-welcome__container__content">
            <prismic-rich-text :field="slice.primary.guest_header" />
            <PrismicImage v-if="slice.primary.guest_image" :image="slice.primary.guest_image"
              class="homepage-welcome__container__image" />
            <p v-if="slice.primary.guest_content" v-html="slice.primary.guest_content.replace(/\n/g, '<br>')"></p>
            <div class="links">
              <div class="links__link" v-if="slice.primary.website_title">
                <div class="links__link__icon">
                  <img src="@/assets/img/website-icon.svg" alt="Network Rail logo">
                </div>
                <a
                    :href="slice.primary.website_link.url"
                    class="links__link__href"
                   :target="slice.primary.website_link.target"
                >
                  {{ slice.primary.website_title }}
                </a>
              </div>
              <div class="links__link" slice.primary.contact_title>
                <div class="links__link__icon">
                  <img src="@/assets/img/contact-icon.svg" alt="Email icon">
                </div>
                <a
                    :href="slice.primary.contact_link.url"
                    class="links__link__href"
                   :target="slice.primary.contact_link.target"
                >
                  {{slice.primary.contact_title}}
                </a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="homepage-welcome__container__nrlogo">
        <img src="@/assets/img/nr-logo.svg" alt="logo">
      </div>
    </b-container>
  </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";
export default {
  name: "HomepageWelcome",
  components: { PrismicImage },
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>


<style lang="scss">
.homepage-welcome {
  &__container {
    padding-block: 90px;
    position: relative;

    &__image {
      margin: 2rem 0;
      text-align: center;
      
      img {
        width: 100%;
        height: auto;
      }
    }

    &__content {
      padding: 0;

      h3 {
        margin-bottom: 1.5rem;
      }

      p {
        line-height: 1.1;
        margin-bottom: 50px;

        @include respond-below(md) {
          font-size: 16px;
        }
      }

      .links {
        display: flex;
        flex-wrap: wrap;

        &__link {
          display: flex;
          align-items: center;
          margin-right: 35px;

          &__icon {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FF6921;
            border-radius: 100%;
            margin-right: 10px;

            img {
              width: 25px;
              height: auto;
            }
          }

          &__href {
            &:hover {
              color: #1b4c75;
              text-decoration: underline !important;
            }
            @include respond-below(md) {
              font-size: 16px;
            }
          }

          @include respond-below(lg) {
            margin-right: 20px;
            margin-bottom: 1rem;
          }
        }

        @include respond-below(sm) {
          display: block;
        }
      }

      @include respond-below(md) {
        padding-left: 0;
      }
    }

    &__nrlogo {
      position: absolute;
      top: 70px;
      right: 0;
      text-align: right;

      @include respond-below(md) {
        top: 0;
        right: 16px;
        max-width: 120px;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
