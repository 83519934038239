<template>
    <section class="story-imagecarousel">
        <b-container :style="getBgStatus">
            <div
                v-if="slice.items.length === 1"
                class="story-imagecarousel__carousel-container"
                :class="slice.primary.remove_bgcolor ? 'story-imagecarousel__carousel-container--noSpace' : ''">
                <div class="story-imagecarousel__carousel-container__image">
                    <PrismicImage :image="slice.items[0].image" :lazyLoad="true"
                        class="story-imagecarousel__carousel-container__image__image" />
                </div>
                <p v-if="slice.items[0].caption_text" class="caption">
                    {{ slice.items[0].caption_text }}
                </p>
            </div>
            <VueSlickCarousel v-else v-bind="options" @init="removeAriaHidden" @afterChange="removeAriaHidden">
                <div
                    v-for="img in slice.items"
                    :key="img.image.url"
                    class="story-imagecarousel__carousel-container"
                    :class="slice.primary.remove_bgcolor ? 'story-imagecarousel__carousel-container--noSpace' : ''">
                    <div class="story-imagecarousel__carousel-container__image">
                        <PrismicImage
                            v-if="img.mobile_image && img.mobile_image.url"
                            :image="img.mobile_image"
                            :lazyLoad="true"
                            class="mobile-only" />
                        <PrismicImage
                            :image="img.image"
                            :lazyLoad="true"
                            :class="{'desktop-only': img.mobile_image && img.mobile_image.url}" />
                    </div>
                    <p v-if="img.caption_text" class="caption">
                        {{ img.caption_text }}
                    </p>
                </div>
            </VueSlickCarousel>
        </b-container>
    </section>
</template>

<script>
import PrismicImage from "@/components/PrismicImage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: "story-imagecarousel",
    components: { VueSlickCarousel, PrismicImage },
    props: {
        slice: {
            type: Object,
            default() {
                return null;
            },
        }
    },
    data() {
        return {
            options: {
                lazyLoad: "ondemand",
                dots: false,
                arrows: true,
                dotsClass: "slick-dots",
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: false,
                            dots: true
                        },
                    },
                ],
            },
        };
    },
    computed: {
        getBgStatus() {
            try {
                const status = this.slice.primary.remove_bgcolor;
                if (status) {
                    return null;
                } else {
                    if(this.slice.primary.bg_color) {
                        return { background: this.slice.primary.bg_color };
                    } else {
                        return { background: '#51ACB8' };
                    }
                }
            } catch (error) {
                // Handle any potential errors here
                console.error("An error occurred in getBg function:", error);
                // Return a default value or handle the error in another way
                return null;
            }
        }

    }
};
</script>

<style lang="scss">
.story-imagecarousel {
    margin-bottom: 80px;

    &__carousel-container {
        padding: 150px;
        padding-bottom: 60px;

        &__image {
            .mobile-only {
                display: none;

                @include respond-below(md) {
                    display: block;
                }
            }

            .desktop-only {
                @include respond-below(md) {
                    display: none;
                }
            }

            img {
                width: 100%;
                height: auto;
            }

            @include respond-below(md) {
                margin-bottom: 10px;
            }
        }

        .caption {
            font-size: 22px;
            line-height: 28px;
            margin-top: 20px;
        }

        &--noSpace{
            padding: 0;
        }

        @include respond-below(ipad-pro) {
            padding: 100px;
            padding-bottom: 60px;
        }

        @include respond-below(md) {
            padding: 50px;

            .caption{
                max-width: none;
                font-size: 16px;
                line-height: 22px;
                margin: 0;
            }
        }

        @include respond-below(sm) {
            padding: 0rem 10px;
        }

    }

    .slick-slider {
        @include respond-below(sm) {
            padding: 20px 0 40px;
        }
    }

    .slick-arrow {
        width: 47px;
        height: 81px;
        background: url("../../../assets/img/slider-arrow-left-shadow.svg") no-repeat;
        background-position: center center;
        background-size: contain;
        z-index: 9;

        &::before {
            display: none;
        }

        &.slick-prev {
            left: 70px;

            @include respond-above(lg) {
                left: 30px;
            }
        }

        &.slick-next {
            right: 70px;

            @include respond-above(lg) {
                right: 30px;
            }

            background-image: url("../../../assets/img/slider-arrow-right-shadow.svg");
        }

        &.slick-disabled {
            opacity: 0.2;
            pointer-events: none;
            cursor: pointer;
        }

        @include respond-below(ipad-pro) {
            height: 50px;
            width: 50px;

            &.slick-prev {
                left: 20px;
            }

            &.slick-next {
                right: 20px;
            }
        }

        @include respond-below(md) {
            height: 30px;
            width: 30px;

            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                right: 0;
            }
        }
    }

    .slick-dots {
      bottom: 10px;
      display: block;
      width: 100%;

      @include respond-below(sm) {
        bottom: initial;
      }

      li {
        margin: auto;
        width: 20px;
        height: 15px;

        button {
          background: #ffffff;
          border-radius: 100%;
          width: 12px;
          height: 12px;
          margin: auto;
          opacity: 0.5;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }

    @include respond-below(sm) {
        margin-bottom: 40px;
    }
}
</style>
